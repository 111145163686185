import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getPlanoRecorrencia(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.get(`/plano/${params.nomePlano}/recorrencia`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getPlanos() {
      return new Promise((resolve, reject) => {
        axios.get('/plano')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getAssinatura() {
      return new Promise((resolve, reject) => {
        axios.get('assinatura')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    reativarAssinatura(context, id) {
      return new Promise((resolve, reject) => {
        axios.post(`assinatura/reativar-assinatura/${id}`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    pagar(context, playload) {
      return new Promise((resolve, reject) => {
        axios.post('assinatura/pagar', playload)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getMLPreferenceId(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/pagamento/mercadopago/${id}`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
